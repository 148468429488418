@import '../../styles/settings.scss';

.zerox-address {
  display: flex;
  white-space:nowrap;
  align-items: center;
  position: relative;
  transition: all 0.2s;
  line-height: 1.2;
  > a {
    margin-left: 4px;
    display: flex;
    margin-top: -1px;
    align-items: center;
    > svg {

      width: 1.75rem;
    }
  }

  .zerox-address__hovered {
    font-size: 1rem;
    position: absolute;
    border-radius: 4px;
    top: -2rem;
    z-index: 999;
    padding: 3px 6px;
    background-color: $black;
    color: white;
  }
}
