@import '../../../styles/mixins.scss';

.tx-id {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
  }

  @include mobile {
    display: none;
  }
}
