@import '../../../styles/settings.scss';

.history__filter-by-status-mobile {
  display: flex;
  align-items: center;
  padding: 1rem 1.7rem;
  justify-content: center;

  > div {
    > div {
      background-color: white;
      position: relative;
      overflow: hidden;
      width: 2.8rem;
      height: auto;

      svg {
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
      }

      > div {
        > div {
          padding-right: 0;
          margin-left: 1rem;
          width: 1rem;
          height: .5rem;
        }
      }
    }
  }

  > span {
    color: $text-secondary;
    font-size: 1.6rem;
    white-space: nowrap;
    margin-right: .4rem;
  }
}