@import '../../styles/mixins.scss';

.button-dialog {
  max-width: 117rem;
  margin: 0 auto;
  position: relative;

  .button-dialog__close {
    cursor: pointer;
    position: absolute;
    right: 2.4rem;
    top: 2rem;
    opacity: 0.5;
  }

  @include mobile {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #fff;
    transition: transform 255ms;
    transform: translateX(-200%);
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // padding-top: 28rem;

    .button-dialog__close {
      top: 1.6rem;
      right: 1.6rem;
    }

    &.--open {
      transform: translateX(0);
    }
  }
}
