@import '../../../styles/settings';

.header__menu {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 2.8rem;

  .header__menu__image {
    position: relative;
    display: inline-block;
    margin-right: 1rem;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 16px;
    background: $primary-light-4;
    overflow: hidden;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $primary-light-3;
      font-size: 1.8rem;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      border: 2px solid #fff;
      object-fit: cover;
    }
  }

  .header__menu__anchor {
    position: relative;
    line-height: 1;
  }

  .header__menu__content {
    position: absolute;
    top: 2rem;
    right: 0;
    z-index: 20;
    transition: all 0.3s;
    opacity: 0;
    border-radius: 4px;
    transform: scale(0) translate3d(0, -2rem, 0);
    transform-origin: 100% 0;

    > li {
      display: flex;
      padding: 0;

      > * {
        padding: 1rem;
      }

      > a {
        height: 100%;
        width: 100%;
      }

      > span {
        font-size: 1.5rem;
      }
      svg {
        margin-right: 0.8rem;
        // fill: $primary-main;
        fill: $primary-light;
        width: 1.6rem;
        height: 1.6rem;
        display: none;
      }
    }

    &.header__menu__content--active {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0);
    }
  }

  .header__menu__icon {
    position: relative;
    color: $darkGrey;
    font-size: 2.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  a,
  a:visited,
  a:active {
    color: $text-black-1;
  }
}
