@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.transfer-address__transfer-suggestions {
  background: $white;
  overflow: hidden;

  .transfer__contact {
    width: 100%;
  }

  @include desktop {
    position: absolute;
    top: 100%;
    z-index: 20;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $grey-border;
    transform: translateY(9px);
  }

  @include mobile {
    margin-top: 1.2rem;
    border-top: 1px solid $grey-border;
  }
}
