@import '../../../styles/settings';

.activity-table-action {
  > div:first-child {
    color: $text-secondary;
    font-size: 1.4rem;
  }

  .copy-to-clipboard {
    margin-right: 0.3rem;
  }

  a {
    color: $link-blue-1;
  }

  > span {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    line-height: 1;

    > img {
      height: 100%;
      width: 1.75rem;
      margin-right: 0.6rem;
      margin-top: -1px;
    }

    a {
      max-width: 100%;
    }
  }
}
