@import '../../../styles/mixins.scss';
@import '../../../styles/settings.scss';

.sell__footer {
  text-align: center;
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid $lightGrey;
  padding-bottom: 2.4rem;

  @include mobile {
    margin: 1rem 1rem 0 1rem;
    padding-bottom: 1.2rem;
  }
}

.sell__sell-tokens {
  h2 {
    text-align: center;
  }

  p {
    display: flex;
    justify-content: space-between;

    &.sell__sell-tokes__exchange-msg {
      display: block;
      font-size: 1.3rem;
      text-align: center;
      color: $text-secondary;
      a {
        color: $link-blue-1;
      }
    }
  }

  .exchange-fee {
    margin-bottom: 0.8rem;
  }

  .no-exchange-fee {
    justify-content: center;
  }

  .error-text {
    text-align: center;
  }

  @include desktop {
    padding: 14.4rem 0;

    h2 {
      margin-bottom: 3.2rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.9rem;

      &:first-of-type {
        margin-bottom: 1.6rem;
      }

      &:last-of-type {
        margin-bottom: 3.2rem;
      }
    }
  }

  @include mobile {
    padding: 2.1rem 0 6.9rem;

    h2 {
      margin-bottom: 7.7rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.4rem;

      &:first-of-type {
        margin-bottom: 1.2rem;
      }

      &:last-of-type {
        margin-bottom: 1.6rem;
      }
    }
  }
}
