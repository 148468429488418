@import '../../../styles/settings';

.activity-table-date {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 1.5rem;
  border-right: 1px solid $lightGrey;

  > div:first-child {
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  > div:last-child {
    font-weight: 400;
    font-size: 2.2rem;
    margin: -1rem
  }
}