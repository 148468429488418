@import '../../styles/settings.scss';
@import '../../styles/mixins.scss';

.balance-card {
  display: inline-flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-top: 1rem;
  padding-right: 1rem;
  height: 10rem;
  min-width: 27.5rem;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.6rem;

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 4px;
  }

  &--EUR36 {
    background-image: url('../../assets/BalanceCard/euro.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    &:after {
      background-image: $gradient-euro;
    }
  }

  &--USD36 {
    background-image: url('../../assets/BalanceCard/dollar.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    &:after {
      background-image: $gradient-dollar;
    }
  }

  &--GBP36 {
    background-image: url('../../assets/BalanceCard/pound.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    &:after {
      background-image: $gradient-pound;
    }
  }

  &--CHF36 {
    background-image: url('../../assets/BalanceCard/franc.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    &:after {
      background-image: $gradient-franc;
    }
  }

  &--ETHER {
    background-image: url('../../assets/BalanceCard/ether.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    &:after {
      background-image: $gradient-ether;
    }
  }

  > div {
    display: flex;
  }

  > div:last-child {
    align-items: center;
  }

  &__icon {
    margin-right: 0.5rem;
    > img {
      width: 3.8rem;
      height: 3.8rem;
    }
  }

  &__balance {
    font-size: 2.5rem;

    span {
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $text-secondary;
    }
  }

  @include desktop {
    width: 27rem;
    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }

  @include mobile {
    width: 100%;
  }
}
