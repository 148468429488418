.verification-user-profile__source {
  > div {
    p {
      font-size: 1.4rem;
    }

    p:first-child {
      margin-bottom: 1.5rem;
      color: #499729;
    }

    p:last-child {
      color: #ff6262;
    }
  }
}
