@import '../../../styles/settings.scss';

.invest-details {
  padding: 2rem;
  padding-top: 3.2rem;
  overflow: hidden;
  max-width: 67rem;

  > h2 {
    margin-bottom: 0.8rem;
  }

  > p {
    margin-bottom: 2.4rem;
    word-break: break-word;
  }

  .invest-details__info-field {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }

    > div:first-child {
      opacity: 0.5;
      font-size: 1.4rem;
    }

    .invest-details__accepted-token {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8rem;

      > div:first-child {
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
        img {
          width: 2.2rem;
          margin-left: 0.5rem;
        }
      }
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  > a {
    display: inline-block;
    margin-top: 3.2rem;
    width: 100%;
    > button {
      width: 100%;
    }
  }
}
