@import '../../../styles/mixins.scss';
@import '../../../styles/settings.scss';

.beneficial-owner {
  .beneficial-owner__heading {
    > h4 {
      font-size: 1.4rem;
      line-height: 1.7rem;
      color: $text-secondary;
      margin-top: 0.3rem;
    }
  }

  .beneficial-owner__checkbox-container {
    display: flex;
    align-items: center;
    > p {
      margin-left: 2rem;
      font-size: 1.4rem;
    }

    > span {
      width: 2rem;
      height: 2rem;
    }
  }

  @include desktop {
    padding: 5.2rem 11.4rem 0.8rem 11.4rem;

    .process-header {
      margin-bottom: 13.8rem;
    }

    .beneficial-owner__checkbox-container {
      margin-top: 2.4rem;
      // padding-right: 28rem;

      > p {
        margin-right: 22rem;
      }

      > .step-2__link {
        margin-left: auto;
        align-self: flex-start;

        > a {
          white-space: nowrap;
          text-decoration: underline;
        }
      }
    }
  }

  @include tablet {
    padding: 5.2rem 5.4rem 0.8rem 5.4rem;
  }

  @include mobile {
    padding: 2.1rem 1.2rem 0.8rem 1.2rem;

    .process-header {
      margin: 1.6rem 0;
    }

    .beneficial-owner__body {
      > h4 {
        margin-bottom: 3rem;
        font-size: 1.8rem;
      }
    }

    .beneficial-owner__checkbox-container {
      align-items: flex-start;

      > p {
        margin-right: 2rem;
        font-size: 1.6rem;
        line-height: 2rem;
      }

      > .step-2__link {
        margin-left: auto;
        align-self: flex-start;

        > a {
          white-space: nowrap;
          font-size: 1.4rem;
          text-decoration: underline;
        }
      }
    }
  }

  .step-2__section {
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: 1px solid $grey-border;

    @include mobile {
      h4 {
        margin-bottom: 1.6rem;
      }
    }
  }
}
