@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.add-wallet-button {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;

    @include mobile {
      width: 2rem;
      height: 2rem;
    }
  }
}
