@import '../../../styles/mixins.scss';

.buy__buy-status {
  p {
    margin-bottom: 3.2rem;
    text-align: center;
  }
}

@include desktop {
  .buy__buy-status {
    padding: 6.4rem 0;

    p {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
}

@include mobile {
  padding: 0 1.2rem;

  .buy__buy-status {
    padding: 6.7rem 0 7.7rem;

    p {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}
