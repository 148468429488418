@import '../../styles/utils.scss';
@import '../../styles/settings.scss';
@import '../../styles/mixins.scss';

.element-form-wrapper {
  @include mobile {
    display: flex;
    flex-direction: column;
  }

  h2 {
    margin: 1.6rem 0;
  }

  .element-form__input-wrapper {
    position: relative;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &.element-form__input-wrapper--select {
      transform: translateY(-0.2rem);
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  p.form-error {
    font-size: 1.2rem;
    color: red;
  }

  p.Mui-disabled {
    display: none;
  }

  .element-form__actions {
    width: 100%;
  }

  button {
    width: 100%;
    margin-top: 2.4rem;
    padding: 1.5rem 1rem;
  }

  .element-form__progress {
    color: #ffffff;
  }

  textarea {
    height: auto;
  }

  .dateOfBirth {
    button {
      margin-top: 0;
      margin-bottom: 0.6rem;
      color: $primary-main;
    }
  }

  .autocomplete-suggestions {
    position: absolute;
    z-index: 1;
    width: 100%;
    margin-top: 1rem;
  }
}
