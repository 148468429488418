@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.documents-upload {
  .process-header {
    margin-bottom: 0;
  }

  .documents-upload__via-mobile {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1.6rem;

    > div {
      font-size: 1.4rem;
      opacity: 0.5;
      margin-top: 0.2rem;
    }
  }

  .documents-upload__document-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    border-bottom: 1px solid $border-light-grey-1;

    &:nth-child(5) {
      border-bottom: none;
    }

    .documents-upload__waiting-for-doc {
      opacity: 0.5;
      display: inline-flex;
      align-items: center;
    }
  }

  .documents-upload__document-wrapper__content {
    flex: 1;
    display: flex;
    align-items: flex-start;

    img {
      width: 7rem;
      margin: 0.5rem 3.5rem 0 2rem;
    }
  }

  h3 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  p {
    font-size: 1.3rem;
    line-height: 1.2;

    & + p {
      margin-top: 1.5rem;
    }
  }

  ul {
    & + p {
      margin-top: 1.5rem;
    }

    li {
      position: relative;
      padding-left: 1.3rem;
      font-size: 1.3rem;
      line-height: 1;

      &:before {
        content: '-';
        position: absolute;
        left: 0;
      }
    }
  }

  .form-header {
    display: flex;
    justify-content: space-between;
  }

  @include desktop {
    padding: 5.2rem 10rem 3.5rem 10rem;

    .documents-upload__document-wrapper {
      flex-direction: row;
      padding: 3rem 0;
    }

    .tier2-form__file-input__container {
      max-width: 35rem;
      min-width: 35rem;
      margin-left: 2rem;
    }
  }

  @include tablet {
    padding: 3.2rem 1rem 0 1rem;
  }

  @include mobile {
    padding: 3.2rem 1rem 0 1rem;

    .documents-upload__document-wrapper {
      flex-direction: column;
      padding: 2rem 0;

      .documents-upload__document-wrapper__content {
        margin-bottom: 3rem;
      }
    }

    .tier2-form__file-input__container {
      width: 100%;
    }
  }
}
