@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.process-welcome-screen {
  > p,
  ul {
    font-size: 1.8rem;
    margin-bottom: 3.2rem;
    line-height: 2.4rem;
  }

  @include desktop {
    padding: 5.2rem 22.4rem 0.8rem 22.4rem;

    .kyc-process-controls {
      margin-top: 11rem;
    }
  }

  @include tablet {
    padding: 5.2rem 5.4rem 0.8rem 5.4rem;

    .kyc-process-controls {
      margin-top: 6rem;
    }
  }

  @include mobile {
    padding: 3.2rem 1rem 0.2rem 1rem;
    height: 100%;

    > p,
    ul {
      line-height: 1.8rem;
      margin-bottom: 2.4rem;
      line-height: 2.4rem;
    }
  }
}
