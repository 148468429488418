@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.invest-transfer-tokens {
  display: flex;
  flex-direction: column;

  button {
    margin-top: 3.2rem;
  }

  .invest-transfer-tokens__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      width: 4rem;
      height: 4rem;
      margin-bottom: 0.8rem;
    }

    > h4 {
      text-align: center;
      margin-bottom: 1.6rem;
    }

    > h3 {
      letter-spacing: -1px;
      text-align: center;
    }

    > :nth-child(3) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    > :nth-child(4) {
      margin-top: 0.6rem;
      color: $text-light-grey-1;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  > hr {
    margin: 3.2rem auto;
    max-width: 63rem;
    border-bottom: 1px solid $grey-border;
    border-top: none;
    width: 100%;
  }

  @include mobile {
    > hr {
      margin: 3.2rem 0;
      border-bottom: 1px solid $grey-border;
      border-top: none;
      width: 100%;
    }
  }
}
