@import '../../styles/base.scss';
@import '../../styles/settings.scss';

.question-mark {
  position: relative;
  display: flex;
  align-items: center;
}

.question-mark__icon {
  cursor: pointer;
  padding: 1rem;
  border: 2px solid $primary-main;
  color: $primary-main;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 500;
}

.question-mark__content {
  cursor: pointer;
  @extend .paper;
  padding: 1.2rem;
  position: absolute;
  right: 0;
  bottom: 4rem;
  max-width: 67rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  white-space: normal;
  z-index: 9999;
  min-width: 24rem;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
}

.question-mark__icon:hover ~ .question-mark__content {
  opacity: 1;
  visibility: visible;
}

.imported-icon {
  cursor: pointer;
}

.imported-icon:hover ~ .question-mark__content {
  opacity: 1;
  visibility: visible;
}
