@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.send-tokens {
  display: flex;
  flex-direction: column;

  button {
    margin-top: 3.2rem;
  }

  .send-tokens__header {
    display: flex;
    align-items: center;
    flex-direction: column;

    > h4 {
      text-align: center;
      margin-bottom: 1.6rem;
    }

    > i {
      color: $primary-light-3;
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 16px;
      background-color: $grey-blue;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      justify-content: center;
      margin-bottom: 1.6rem;
    }

    > h3 {
      letter-spacing: -1px;
      text-align: center;
    }

    > :nth-child(3) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    > :nth-child(4) {
      margin-top: 0.6rem;
      color: $text-light-grey-1;
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }

  > hr {
    margin: 3.2rem 0;
    border-bottom: 1px solid $grey-border;
    border-top: none;
  }

  .send-tokens__avatar {
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 1.3rem;
  }

  @include desktop {
    > hr {
      width: 120%;
      transform: translateX(-10%);
    }
  }

  @include mobile {
    > hr {
      margin: 3.2rem 0;
      border-bottom: 1px solid $grey-border;
      border-top: none;
      width: 100%;
    }
  }
}
