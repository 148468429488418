@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/base.scss';

.tier1-form {
  h3 {
    margin-bottom: 2.6rem;

    &:last-of-type {
      margin-top: 3rem;
    }
  }

  .tier1-form__field-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &.checkbox {
      margin-bottom: 2rem;
    }
  }

  form > p.form-error {
    margin-bottom: 2rem;

    & + .kyc-process-controls {
      margin-top: 0;
    }
  }

  .kyc-process-controls {
    min-width: 100%;
    margin-top: 3.5rem;
  }

  .tier1-form__checkboxes {
    display: flex;
    justify-content: space-between;
  }

  .us-person-text {
    > div {
      margin-bottom: 0.7rem;
      font-weight: 500;
    }

    ul {
      font-size: 1.4rem;
      min-width: 20vw;
    }

    li {
      margin-bottom: 0.8rem;
    }
  }

  @include desktop {
    padding: 5.2rem 10rem 3.5rem 10rem;

    .element-form__input-wrapper {
      max-width: calc(50% - 2rem);
      min-width: calc(50% - 2rem);
    }
  }

  @include mobile {
    padding: 3.2rem 1rem 0 1rem;
    height: 100%;

    .tier1-form__field-group {
      flex-direction: column;
    }
  }
}
