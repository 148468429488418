@import '../../styles/settings.scss';
@import '../../styles/mixins.scss';

.home-page {
  &__activity {
    > h2 {
      font-size: 2.5rem;
      font-weight: 400;
      margin-bottom: 1.6rem;
    }
  }

  &__no-activity {
    border-radius: 12px;
    margin: 3rem 0;
    padding: 3rem;
    > h3 {
      font-weight: 400;
    }
    > p {
      color: $text-secondary;
      margin-top: 2rem;
    }
  }

  @include desktop {
    background: linear-gradient(
        to bottom,
        $headerGrey 0,
        $headerGrey 209px,
        $backgroundGrey 209px,
        $backgroundGrey 100%
      )
      no-repeat;

    &__user-actions {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 3.7rem;
    }
  }

  @include mobile {
    &__user-actions {
      > div:first-child {
        margin-bottom: 20px;
      }

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
