@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.kyc-process-controls {
  display: flex;
  width: 100%;

  button {
    padding: 1.6rem 3.2rem;
  }

  > a {
    margin-right: 1.6rem;
    button {
      margin-top: 0;
      padding: 1.6rem 3.2rem;
    }
  }

  > button:first-of-type {
    flex: 1;
    margin-top: 0;
  }

  @include mobile {
    margin-top: 3rem;
    flex-direction: column-reverse;

    > a {
      margin-right: 0;

      > button {
        background: $white !important;
        width: 100%;
      }
    }
  }
}

.kyc-process-controls__error {
  display: flex;
  margin-top: 0.5rem;
}
