.element-form__input-wrapper {
  &.--checkboxes {
    > div:first-child {
      color: rgba(0, 0, 0, 0.54);
      font-size: 1.4rem;
    }
    > div:nth-child(2) {
      display: flex !important;
      flex-wrap: nowrap;

      > label {
        display: inline-flex;
      }
    }
  }
}
