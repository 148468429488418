@import '../../styles/settings.scss';
@import '../../styles/utils.scss';
@import '../../styles/mixins.scss';

.add-mm-wallet {
  img {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;

    &.error-text {
      font-size: 1.2rem;
    }
  }

  .add-wallet-form {
    margin-top: 2.4rem;
  }

  @include desktop {
    min-width: 50rem;
    max-width: 50rem;

    margin-top: -1.5rem;
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .add-mm-wallet__submitted {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      margin-top: 1.6rem;

      > svg {
        margin: 0;
        margin-top: -2px;
        margin-right: 1rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
