@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.transfer__contact {
  display: flex;
  position: relative;
  align-items: center;
  padding: .5rem 1rem;
  cursor: pointer;

  &.transfer__contact--alt {
    flex-direction: column;

    .transfer__contact__avatar {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  .transfer__contact__avatar {
    width: 4.6rem;
    height: 4.6rem;
    margin-right: 1rem;
  }

  @include desktop {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  @include mobile {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}
