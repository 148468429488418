@import '../../../styles/utils.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/settings.scss';

.tokens-transfer-option {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    @include mobile {
      padding-top: 3.2rem;
    }
    text-align: center;
  }

  .tokens-transfer-option__kyc-verifications {
    margin-top: 3.2rem;
    display: inline-flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }

    .tokens-transfer-option__kyc-verification {
      padding: 1.4rem;
      margin-bottom: 2rem;
      display: inline-flex;
      flex-direction: column;
      white-space: nowrap;

      > h4 {
        font-weight: 500;
      }

      .tokens-transfer-option__smart-contract {
        margin: auto 0;
        font-size: 1.8rem;
        white-space: nowrap;
      }
    }

    .tokens-transfer-option__mid-section {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      > div:first-child {
        > img {
          width: 3rem;
        }
        @extend .paper;
        padding: 1.2rem 2.4rem;
        display: flex;
        align-items: center;
        font-size: 2.4rem;

        > div:first-child {
          margin-right: 0.5rem;
        }

        > svg {
          margin-left: 1.2rem;
          fill: $black;
        }
      }
    }
  }

  .tokens-transfer-option__buttons {
    display: inline-flex;
    margin-top: 2.4rem;

    > button {
      width: 100%;
    }

    > button:first-child {
      margin-right: 0.8rem;
    }

    > button:nth-child(2) {
      flex: 40%;
    }
  }
}
