@import '../../../styles/settings';

.notification {
  position: relative;
  display: inline-flex;
  padding: 1.6rem;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 1.6rem;
      right: 1.6rem;
      height: 1px;
      background: $notificationsBorder;
      content: '';
    }
  }

  &.notification--unread {
    background: $notificationsGrey;
  }

  img {
    width: 4rem;
    height: 4rem;
    display: block;
    margin-right: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    > span:first-child {
      font-size: 1.4rem;
      font-weight: 500;
    }

    > span:last-child {
      font-size: 1.2rem;
      color: $text-secondary;
      line-height: 14px;
    }
  }

  &__time {
    font-size: 1.2rem;
    color: $text-secondary;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
