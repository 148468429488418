// COLORS

$primary-light: #6bafe6;
$primary-light-2: #e4eefa;
$primary-light-3: #308fee;
$primary-light-4: rgba(234, 245, 253, 1);

$primary-main: #308fee;
$primary-dark: #208fea;
$primary-dark-2: #316d9c;

$secondary-light: #ff7961;
$secondary-main: #f44336;
$secondary-dark: #ba000d;

$text-disabled: rgba(0, 0, 0, 0.38);
$text-hint: rgba(0, 0, 0, 0.38);
$text-primary: rgba(0, 0, 0, 0.87);
$text-secondary: rgba(0, 0, 0, 0.54);

$white: #fff;
$black: #01152c;

$link-blue-1: #1f8eea;

$text-black-1: #01152c;

$text-light-grey-1: rgba(1, 21, 44, 0.5);
$text-light-grey-2: rgba(1, 21, 44, 0.75);
$text-light-grey-3: rgba(1, 21, 44, 1);
$text-light-grey-4: rgba(1, 21, 44, 0.2);

$green-1: rgba(72, 214, 182, 0.1);
$green-2: rgba(72, 214, 182, 1);

$background-grey-1: #f8f9fb;

$border-light-grey-1: rgba(179, 198, 213, 0.25);

$grey-1: rgba(1, 21, 44, 1);
$grey-border: rgba(1, 21, 44, 0.1);
$darkGrey: #949ca8;
$headerGrey: #f7f8fb;
$lightGrey: #ededed;
$backgroundGrey: #fcfcfc;
$notificationsGrey: #f9f7ff;
$notificationsBorder: #e5effa;
$grey-blue: #ecf6fe;
$red-light: #ffeded;

$yellow-light: #f9f6df;
$green-light: #ecfaf7;
$red-1: #ff2525;

// Gradients
$gradient-primary: linear-gradient(
  to left bottom,
  $primary-dark,
  $primary-light
);
$gradient-secondary: linear-gradient(
  180deg,
  rgba(99, 180, 245, 0.1) 0%,
  rgba(1, 126, 229, 0.1) 100%
);
$gradient-3: linear-gradient(
  225.95deg,
  rgba(105, 184, 242, 1) 0%,
  rgba(1, 126, 229, 1) 100%
);
$gradient-3: linear-gradient(
  225.95deg,
  rgba(105, 184, 242, 0.1) 0%,
  rgba(1, 126, 229, 0.1) 100%
);
$gradient-4: linear-gradient(217.42deg, #63b4f5 0%, #017ee5 100%);
$gradient-green-1: linear-gradient(225.95deg, #48d6b6 0%, #32b396 100%);
$gradient-red-1: linear-gradient(225.95deg, #ff2525 0%, #fd6767 100%);

$gradient-euro: linear-gradient(224.83deg, #69b4ef 0%, #623479 100%);
$gradient-dollar: linear-gradient(224.83deg, #f5eba9 0%, #ff6464 100%);
$gradient-franc: linear-gradient(224.08deg, #c86dd7 0%, #3023ae 100%);
$gradient-pound: linear-gradient(224.83deg, #00a679 0%, #623479 100%);
$gradient-ether: linear-gradient(224.83deg, #83d7e6 0%, #9eacec 100%);

$box-shadow-1: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
$box-shadow-1-hover: 0 10px 12px 0 rgba(0, 0, 0, 0.1);
