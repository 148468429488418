@import '../../styles/settings.scss';

.page-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // z-index of MUI dialog is 1300
  z-index: 1305;
  background: $gradient-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 1s;
  opacity: 0;

  &.--active {
    opacity: 1;
  }
}
