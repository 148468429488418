@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.quick-actions {
  display: inline-flex;
  flex-direction: column;

  > div:first-child {
    text-transform: uppercase;
    font-size: 1.4rem;
    color: $text-secondary;
    margin-bottom: 1rem;
  }

  > div:last-child {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    background-color: $grey-blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 1rem;
    width: 12rem;
    height: 10rem;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    > div:first-child {
      background-image: $gradient-primary;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 0.5rem;
      color: $white;
      transition: all 0.1s;
    }

    > div:last-child {
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $primary-main;
      transition: all 0.1s;
    }

    &--active {
      &:hover {
        background-color: none;
        background-image: $gradient-primary;
        div:first-child {
          background-image: none;
          background-color: $grey-blue;
          color: $primary-main;
          font-weight: 500;
        }
        div:last-child {
          color: $white;
        }
      }
    }

    &--inactive {
      opacity: 0.5;
    }
  }

  @include mobile {
    width: 100%;
    > div:last-child {
      display: flex;
      justify-content: center;
    }
    &__button {
      padding: 0;
      margin-bottom: 1rem;
      width: 30%;

      > div:last-child {
        font-weight: 500;
        font-size: 1.6rem;
      }

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    &__button:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
