@import '../../styles/settings.scss';
@import '../../styles/mixins.scss';

.history {
  &__loader {
    display: flex;
    justify-content: center;
    margin-top: 14.4rem;
  }
  &__content {
    max-width: 117rem;
    margin: 0 auto;
  }

  &__filters {
    margin-bottom: 3.2rem;
    display: flex;
    position: relative;

    > *:not(:last-child) {
      margin-right: 2rem;
    }

    .history__filters__reset-btn {
      background-color: $white;
      box-shadow: $box-shadow-1;
      color: $text-secondary;
      padding: .3rem .5rem;
      border-radius: 4px;
      font-size: 1.2rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2.6rem;
      margin: 0;

      @include mobile {
        bottom: -2.8rem;
        font-size: 1.4rem;
        left: calc(100% - 5.4rem);
        white-space: nowrap;
      }

      &:hover {
        background-color: darken($white, 3);
      }
    }
  }

  &__filter-loader-wrapper {
    position: relative;
  }

  &__filter-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      position: relative;
      opacity: 1;
      z-index: 15;
    }
  }

  &__filter-no-results {
    border-radius: 12px;
    margin: 3rem 0;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: $text-secondary;
      margin-right: .5rem;
    }
  }

  &__filter-resetBtn {
    font-size: 1.4rem;

    > span:first-child {
      color: $white;
      border-radius: 4px;
      padding: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $primary-main;
    }
  }

  &__filter-loader-wrapper {
    position: relative;
  }

  &__filter-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      position: relative;
      opacity: 1;
      z-index: 15;
    }
  }

  &__filter-no-results {
    border-radius: 12px;
    margin: 3rem 0;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: $text-secondary;
      margin-right: .5rem;
    }
  }

  &__filter-resetBtn {
    font-size: 1.4rem;

    > span:first-child {
      color: $white;
      border-radius: 4px;
      padding: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $primary-main;
    }
  }

  &__no-activity {
    border-radius: 12px;
    margin: 3rem 0;
    padding: 3rem;
    > h3 {
      font-weight: 400;
    }
    > p {
      color: $text-secondary;
      margin-top: 2rem;
    }
  }

  @include mobile {

    &__filter-no-results {
      flex-direction: column;

      > p {
        margin-bottom: .8rem;
        text-align: center;
        > span {
          margin-top: .8rem;
          width: 100%;
          display: block;
        }
      }
    }
    &__filters {
      margin-top: 2.4rem;
      width: 100%;

      > div:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}