@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.invest {
  .invest__header {
    display: flex;
    a {
      color: $text-black-1;
    }
  }

  .add-button {
    margin-right: 1.2rem;
    justify-self: flex-end;
  }

  .invest__cards {
    margin-top: 3.2rem;
    flex-wrap: wrap;
    display: flex;

    > div {
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }

  @include mobile {
    .invest__cards {
      > div {
        margin-bottom: 2rem;
        margin-right: 0;
      }
    }
  }
}
