@import '../../../styles/mixins.scss';
@import '../../../styles/settings.scss';
@import '../../../styles/utils.scss';

.invest-payment-method {
  display: flex;
  flex-direction: column;

  > h2 {
    text-align: center;
    margin-bottom: 3.2rem;
  }

  &__separator {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 0 2rem;
  }

  &__buttons {
    display: flex;

    &--heading {
      text-transform: none;
      margin-bottom: 5rem;
    }

    button {
      background-image: none;
      border: 0 solid rgba(36, 145, 233, 0.2);
      box-shadow: 0 10px 20px 0 rgba(28, 141, 233, 0.1);
      width: 26.2rem;
      height: 22.1rem;
      border-radius: 12px;
      > * {
        color: $text-black-1;
      }

      span:first-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: -1rem;
      }
    }

    &--icon {
      > img {
        width: 7.2rem;
        height: 7.2rem;
      }
    }
  }

  @include mobile {
    padding: 2rem 1.2rem;
    margin-top: 7.2rem;
    > h2 {
      @include mobile {
        position: absolute;
        right: 0;
        top: 2.5rem;
        left: 0;
      }
    }
    &__separator {
      display: none;
    }
    &__buttons {
      flex-direction: column;

      &--heading {
        margin: 0;
        width: 100%;
        height: 100%;
      }

      button {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $grey-border;
        margin-bottom: 1rem;
        padding: 2rem;

        align-items: center;
        display: flex;

        span:first-child {
          margin: 0;
        }

        > span:first-child {
          flex-direction: row-reverse;
          display: flex;
          justify-content: flex-end;
          margin-top: 0;
        }
      }

      &--icon {
        width: 4rem;
        height: 3.7rem;
        display: flex;
        align-items: center;
      }
    }
  }
}
