@import '../../../styles/settings';

.activity-table-amount {
  > div:first-child {
    font-weight: 500;
  }

  > div:last-child {
    color: $text-secondary;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
