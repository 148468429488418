@import '../../styles/mixins.scss';

.upload-documents-view {
  max-width: 67rem;
  margin: 0 auto;

  padding: 10rem;
  padding-top: 2rem;

  .upload-documents-view__header {
    margin-bottom: 16rem;
  }

  > h1 {
    margin-bottom: 3.2rem;
  }

  .upload-documents__form {
    .upload-documents__form-field {
      > h3 {
        margin-bottom: 1.6rem;
        opacity: 0.8;
      }
      margin-bottom: 2.4rem;
    }
  }

  @include mobile {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 100%;

    .upload-documents-view__header {
      margin-top: auto;
      margin-bottom: 2.4rem;
    }
  }
}
