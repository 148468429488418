@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.walletless-mode {
  display: flex;
  max-width: 100%;
  opacity: 0.5;
  font-size: 1.4rem;
  align-items: center;

  > svg {
    margin-right: 0.6rem;
    display: block;
    width: 2rem;
    height: 2rem;
    fill: $primary-main;
  }
  > div {
    margin-top: 3px;
  }
}

.wallet-login-mode {
  display: flex;
  align-items: center;

  @include mobile {
    align-items: flex-start;
  }

  > svg {
    display: block;
    width: 2.25rem;
    height: 2.25rem;
    color: $primary-main;
    margin-right: 0.6rem;
    cursor: pointer;
  }

  > div {
    margin-top: 3px;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    max-width: calc(100% - 2.85rem);
  }

  .wallet-login-mode__name {
    display: flex;
    margin-right: 1rem;
    align-items: center;

    > div:first-child {
      font-weight: 500;
      margin-right: 0.6rem;
    }

    > div:last-child {
      color: $text-secondary;
    }

    @include mobile {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .wallet-login-mode__diverged-network {
    color: $darkGrey;
  }

  @include mobile {
    // flex-direction: column;
    margin-bottom: 0.8rem;
  }
}
