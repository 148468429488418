@import '../../../styles/mixins.scss';
@import '../../../styles/settings.scss';

.verification-user-profile {
  h4 {
    margin-bottom: 1.5rem;
  }

  @include desktop {
    padding: 5.2rem 11.4rem 0.8rem 11.4rem;

    .verification-user-profile__row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4rem;

      > div:first-child {
        margin-right: 7.2rem;
      }

      > div {
        width: calc(50% - 3.6rem);
      }
    }

    .kyc-process-controls {
      margin-top: 3.4rem;
    }
  }

  p.verification-user-profile_error,
  p.form-error {
    min-width: 100%;
    color: #ff6262;
    font-size: 1.4rem;
  }

  @include tablet {
    padding: 3.2rem 4rem 0.8rem 4rem;
  }

  @include mobile {
    padding: 2.1rem 1.2rem 0.8rem 1.2rem;

    .verification-user-profile__row {
      margin-bottom: 3.2rem;

      > div:first-child {
        margin-bottom: 3.2rem;
      }
    }
  }
}
