@import '../../styles/settings.scss';
@import '../../styles/utils.scss';

.avatar__container {
  position: relative;

  &:hover > .avatar__controls {
    opacity: 1;
    visibility: visible;
  }

  .avatar {
    position: relative;
    background: $primary-light-4;
    border-radius: 16px;

    .error-text {
      font-size: 1.2rem;
      position: absolute;
      padding: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background-color: $primary-light-4;
      text-align: center;
      width: 100%;
      z-index: 300;
    }

    input {
      position: absolute;
      left: -9999px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $primary-light-3;
    }
  }

  .avatar__controls {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    display: inline-flex;
    z-index: 10;
    transition: all 0.2s;
    padding: 0.4rem;
    background-color: $white;
    @extend .paper;

    svg {
      width: 2.25rem;
      height: 2.25rem;
      color: $primary-main;
      cursor: pointer;
    }

    .avatar__upload {
      display: inline-flex;
      margin-right: 0.5rem;

      input {
        position: absolute;
        left: -9999px;
      }
    }

    .avatar__remove {
      padding: 0;
      padding: 2px;
      font-size: 1.2rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      height: 2.25rem;
      width: 2.25rem;
      border-radius: 0;
    }
  }
}
