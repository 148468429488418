@import '../../../styles/settings.scss';
@import '../../../styles/utils.scss';
@import '../../../styles/mixins.scss';

.buy-step0 {
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 3.2rem;
  }

  > h2 {
    text-align: center;
    margin-bottom: 3.2rem;
  }

  .buy-step0__options {
    display: flex;
    margin-bottom: 3.2rem;

    @include mobile {
      flex-direction: column;
    }
  }

  .buy-step0__option {
    @extend .paper;
    padding: 2rem;
    cursor: pointer;
    transition: box-shadow 0.2s;
    max-width: 30rem;
    display: flex;

    @include mobile {
      max-width: 100%;
    }

    > div {
      > h3 {
        margin-bottom: 0.5rem;
        font-weight: 500;
      }

      > p {
        font-weight: 300;
      }
    }

    &:first-child {
      margin-right: 3.2rem;

      @include mobile {
        margin-right: 0;
        margin-bottom: 0.8rem;
      }
    }

    > div:first-child {
      display: flex;
      align-items: center;
      margin-bottom: 1.6rem;
    }

    svg {
      fill: $primary-main;
      margin-right: 1.2rem;
      font-size: 3.2rem;
    }

    &:hover {
      box-shadow: $box-shadow-1-hover;
    }

    &--hidden {
      opacity: 0.5;
      cursor: default;

      &:hover {
        box-shadow: $box-shadow-1;
      }
    }
  }
}
