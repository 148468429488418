@import '../../../styles/mixins.scss';

.step-button {
  > * {
    padding: 0;
    font-size: 1.6rem;
  }
  > span:first-of-type {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    line-height: 19px;
    padding: 0.4rem;

    @include mobile {
      font-size: 1.6rem;
      padding: 1rem 0;
      border-radius: 4px;
    }
  }

  &.step-button--submitting {
    > span:first-of-type {
      justify-content: center;
    }
  }
}
