@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.buy {
  max-width: 117rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .error-text {
    text-align: center;
    margin-top: 0.3rem;
  }

  &__content {
    max-width: 63rem;
    width: 100%;
    margin: 0 auto;

    @include desktop {
      padding-top: 14.4rem;
    }

    @include mobile {
      margin: 0;
      padding: 2rem 1.2rem;
    }
  }

  &__footer {
    text-align: center;
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid $lightGrey;
    padding-bottom: 2.4rem;
  }

  @include mobile {
    &__footer {
      margin: 1rem 1rem 0 1rem;
      padding-bottom: 1.2rem;
    }
  }
}
