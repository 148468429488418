@import '../../../styles/settings.scss';

.header__mobile-dropdown {
  position: fixed;
  left: 0;
  right: 0;
  top: 8.7rem;
  bottom: 0;
  z-index: 10;
  padding: 3rem 1rem;
  background: #f6f7fa;
  overflow: scroll;
  transition: all 0.2s;
  transform: scale(0);
  transform-origin: 50% 0;
  // -webkit-overflow-scrolling: touch;

  &.active {
    transform: scale(1);
  }

  ul {
    margin: 4rem 1rem 1rem;
    padding: 1.5rem;

    li:not(:last-child) {
      margin-bottom: 1rem;
      border-bottom: 1px solid $lightGrey;
      padding-bottom: 1rem;
    }

    a,
    span {
      position: relative;
      display: block;
      color: #272727;
      cursor: pointer;
    }

    > span {
      padding-bottom: 1rem;
      padding-right: 3rem;
    }
  }

  .header__mobile-dropdown__icon {
    position: absolute;
    right: 0;
    color: $darkGrey;
  }
}
