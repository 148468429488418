@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.available-balance {
  padding: 1.2rem;
  color: $text-secondary;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.7rem;
}
