@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.user-profile {
  display: flex;

  &.user-profile--alt {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .user-profile__avatar {
      margin: 0 0 2rem;
    }

    .user-profile__buttons {
      align-items: center;
    }
  }

  &__buttons {
    display: inline-flex;
    align-items: center;

    > a:first-child {
      margin-right: 1rem;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;

      button {
        font-size: 1.4rem;
      }

      > a:first-child {
        margin-right: 0;
        margin-bottom: 0.6rem;
      }
    }

    &--awaiting {
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 500;
      background-color: $lightGrey;
      border-radius: 4px;
      padding: 0.8rem 1.6rem;
      line-height: 1.4rem;
      margin-right: 1rem;
      min-height: 3.6rem;
      min-width: 6.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      @include mobile {
        margin-right: 0;
        margin-bottom: 1rem;
        font-size: 1.4rem;
      }

      &:hover {
        cursor: default;
      }
    }
  }

  &__avatar {
    position: relative;

    .avatar__container {
      width: 10.5rem;
      height: 10.5rem;

      .user-profile__avatar__image {
        width: 10.5rem;
        height: 10.5rem;
      }
    }

    margin-right: 1.6rem;

    > div:first-child {
      img {
        height: 10.5rem;
        width: 10.5rem;
        border-radius: 12px;
      }

      img {
        object-fit: cover;
      }
    }

    div {
      border-radius: 12px;
    }

    div {
      position: relative;
      display: block;
      background: $primary-light-4;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $primary-light-3;
        font-size: 4rem;
        opacity: 1;
        visibility: visible;
        transition: all 0.2s;
      }
    }

    .user-profile__avatar__badge {
      position: absolute;
      left: 8.3rem;
      top: 8rem;
      display: block;
      width: 3.4rem;
      height: 4.1rem;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        transform: translate(-50%, -50%);
        font-size: 1.3rem;
      }

      &.user-profile__avatar__badge--tier0 {
        background: url('../../assets/icons/tier0-badge.svg') no-repeat center;
      }

      &.user-profile__avatar__badge--tier1 {
        background: url('../../assets/icons/tier1-badge.svg') no-repeat center;
      }

      &.user-profile__avatar__badge--tier2 {
        background: url('../../assets/icons/tier2-badge.svg') no-repeat center;
      }
    }

    > span:last-of-type {
      position: relative;
      display: block;
      width: 8rem;
      height: 0.5rem;
      background: $primary-light-4;
      border-radius: 3px;
      overflow: hidden;
      margin-top: 0.4rem;

      &.Tier_0:after {
        width: 9%;
      }

      &.Tier_1:after {
        width: 50%;
      }

      &.Tier_2:after {
        width: 100%;
      }

      &:after {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        background: $primary-light-3;
        content: '';
        transition: all 0.3s;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .user-profile__buttons {
      margin-top: auto;
    }

    > p {
      margin: 0 0 0.4rem;
      color: $darkGrey;
      font-size: 1.4rem;

      > i {
        margin-left: 0.5rem;
      }

      span {
        color: $text-primary;
        font-size: 1.6rem;
      }

      &:nth-of-type(2) {
        margin-bottom: 0.5rem;
      }

      &:not(:first-of-type) {
        text-decoration: underline;
        word-break: break-all;
      }
    }
  }

  @include mobile {
    width: 100%;
    overflow: hidden;
  }
}
