@import '../../styles/settings.scss';
@import '../../styles/mixins.scss';

.choose-amount-form {
  display: flex;
  border-radius: 4px;
  border: 1px solid $grey-border;
  padding: 1.3rem;
  height: 7rem;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: '';
    height: 2px;
    width: 0%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: $primary-main;
    transition: width 0.2s;
  }

  &:focus-within {
    &::after {
      width: 100%;
    }
    @include mobile {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  > div:first-child {
    border-right: 1px solid $grey-border;
  }

  > div:last-child {
    width: 60%;
    margin-left: 1.3rem;
    position: relative;
  }

  &__number-input {
    transform: translateY(0.2rem);
    margin-bottom: -2px;
  }

  @include mobile {
    flex-direction: column;
    height: 100%;
    padding: 0;
    border: none;
    box-shadow: none;

    > div {
      label {
        margin-left: 2rem;
        margin-top: 0.75rem;
      }
      padding: 1rem 2rem;
      padding-bottom: 0;
      border-radius: 4px;
      box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.02);
    }

    > div:first-child {
      border: none;
      border: 1px solid $grey-border;

      &:focus-within {
        border-bottom: 2px solid $primary-main;
        padding-bottom: 0;
        margin-bottom: -1px;
      }
    }

    > div:last-child {
      width: 100%;
      margin: 0;
      margin-top: 1rem;
      border: 1px solid $grey-border;

      svg {
        position: absolute;
        top: -20%;
        right: 0;
      }
    }

    &__number-input {
      transform: none;
    }
  }
}
