@import '../../styles/mixins.scss';

.balance-cards {
  padding-bottom: 0.5rem;

  &.--space-between {
    justify-content: space-between;
  }

  @include desktop {
    display: flex;
    flex-wrap: wrap;
  }

  @include tablet {
    display: flex;
    overflow: auto;
    flex-wrap: nowrap;
  }

  @include mobile {
    display: flex;
    flex-wrap: wrap;
  }
}
