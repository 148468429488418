@import './settings.scss';
@import './mixins.scss';

.wrapper {
  max-width: 117rem;
  margin: 0 auto;
  padding-bottom: 2.4rem;

  @include mobile {
    margin: 0 1rem;
  }
}

.paper {
  background-color: $white;
  box-shadow: $box-shadow-1;
  border-radius: 10px;
  overflow: hidden;

  &.no-radius {
    border-radius: 0;
  }
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hr-light {
  margin: 4.4rem 0;
  border-bottom: 1px solid $grey-border;
  border-top: none;
}

.MuiPickersToolbar-toolbar {
  button {
    background: transparent;
  }
}

.error-text {
  color: $red-1;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.dialog-content {
  position: relative;
  padding: 3.2rem;

  @include mobile {
    display: flex;
    height: 100%;
    padding: 2rem;

    svg.add-wallet-dialog__close {
      right: 1rem;
    }
  }
}

svg.dialog-close {
  position: absolute;
  right: 2.5rem;
  top: 2.5rem;
  fill: $darkGrey;
  cursor: pointer;
}

.icon-success {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 0;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: $gradient-green-1;
  color: #fff;
  font-size: 3.5rem;
  cursor: pointer;
}
