@import '../../../styles/settings.scss';
@import '../../../styles/mixins.scss';

.invest-card {
  border-bottom: 4px solid $primary-main;
  display: inline-flex;
  flex-direction: column;
  padding: 1.2rem;
  white-space: wrap;
  word-break: break-word;
  min-height: 36rem;

  @include desktop {
    max-width: 30%;
    width: 100%;
  }

  .invest-card__heading {
    margin-bottom: 2.4rem;

    .invest-card__heading__top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .button-dialog-wrapper {
        > span {
          visibility: hidden;
          opacity: 0;
        }
      }

      > h3 {
        font-size: 2.4rem;
        font-weight: 500;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        > span {
          position: absolute;
          font-weight: 400;
          font-size: 1.4rem;
          border-radius: 4px;
          right: -5rem;
          top: -1.4rem;
          color: $primary-main;
        }
      }

      .dropdown-menu {
        margin-bottom: 0.8rem;
      }
    }

    > p {
      margin-bottom: 0.4rem;
      margin-top: 2.4rem;   
    }

    > div {
      font-size: 1.4rem;
    }
  }

  .invest-card__buttons {
    display: flex;
    margin-top: auto;

    > a {
      display: block;
      margin-left: 1.2rem;
      flex: 1;
      button {
        width: 100%;

        span {
          display: flex;
          align-items: center;
        }

        svg {
          margin-right: 0.5rem;
          width: 2rem;
        }
      }
    }
  }

  .invest-card__heading__status {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    > svg {
      margin-left: 0.6rem;
      width: 2.25rem;
      align-self: center;
      fill: $primary-main;
    }
  }

  &--EUR36 {
    background-image: url('../../../assets/BalanceCard/euro.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  &--CHF36 {
    background-image: url('../../../assets/BalanceCard/franc.svg');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  @include mobile {
    width: 100%;
  }
}
