@import '../../styles/mixins';
@import '../../styles/settings';

.activity-table {
  width: 100%;

  &__row {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;

    > .activity-th-date {
      margin-right: 2rem;
      align-items: center;
      display: flex;
    }

    > .activity-th-action {
      width: calc((100% - 7rem) / 2);
      text-align: left;
    }

    > .activity-th-txid {
      width: calc((100% - 7rem) / 8);
      text-align: center;
      @include mobile {
        display: none;
      }
    }

    > .activity-th-message {
      width: calc((100% - 7rem) / 12);
      text-align: center;

      @include mobile {
        display: none;
      }
    }

    .activity-th-who {
      width: calc((100% - 7rem) / 12);
      text-align: center;

      @include mobile {
        display: none;
      }
    }

    > .activity-th-status {
      width: calc((100% - 7rem) / 5);
      text-align: right;

      @include mobile {
        width: calc((100% - 7rem) / 2);
      }
    }

    > .activity-th-amount {
      width: calc((100% - 7rem) / 5);
      text-align: right;

      @include mobile {
        margin-left: auto;
        width: calc((100% - 7rem) / 4);
      }
    }
  }

  &__head {
    color: $text-secondary;
    font-weight: 500;
    font-size: 1.4rem;
  }

  &__body {
    background-color: $white;
    box-shadow: $box-shadow-1;
    border-radius: 10px;
  }

  @include mobile {
    &__row {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1.5rem;
      flex-wrap: wrap;
      width: 100%;

      &:not(:last-child) {
        > div:nth-child(3) {
          padding-bottom: 0.5rem;
          border-bottom: 1px solid $grey-border;
        }
      }

      > div:first-child {
        margin-right: 2rem;
        align-items: center;
        display: flex;
      }

      > div:nth-child(2) {
        text-align: left;
        flex: 1;
      }

      > div:nth-child(3) {
        text-align: left;
        order: 4;
        min-width: 100%;
      }

      > div:nth-child(4) {
        width: calc((100% - 7rem) / 3);
        text-align: right;
      }
    }
  }
}
