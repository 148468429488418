@import './mixins.scss';
@import './utils.scss';

html {
  height: 100%;
  box-sizing: border-box;
  font-size: 62.5%;

  @include mobile {
    font-size: 57.5%;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  height: 100%;
  color: $text-black-1;
  background-color: $backgroundGrey;
  //background: linear-gradient(to bottom, orange 0,red 200px,#fff 200px,#fff 100%) no-repeat;
  line-height: 1.6;
  font-size: 16px;
  font-family: 'Rubik', sans-serif;
  overflow: hidden;

  @include tablet {
    padding: 0 2rem;
  }

  @include mobile {
    background: none;
    background-color: $headerGrey;
  }
}

#root > div {
  flex: 1;
}

body > div[role='tooltip'] {
  z-index: 20;
}

#root {
  margin: 0 auto;
  height: 100%;
  overflow: scroll;

  @include mobile {
    -webkit-overflow-scrolling: touch;

    padding-top: 87px;
    overflow: auto;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h2 {
  font-size: 2.4rem;
  line-height: 2.8rem;
}

h3 {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

p {
  font-size: 1.6rem;
  line-height: 1.9rem;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: $primary-main;
}

img {
  max-width: 100%;
  height: auto;
}

strong {
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition-delay: 999999s;
  transition-property: background-color, color;
}
