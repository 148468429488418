@import '../../styles/mixins.scss';
@import '../../styles/settings.scss';

.payment-info {
  text-align: center;

  &.__alt {
    padding: 2.4rem;
    max-width: 65rem;
  }

  .payment-info__close {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    z-index: 5;
    color: rgba(1, 21, 44, 0.2);
    font-size: 2.4rem;
    cursor: pointer;
  }

  &__content {
    text-align: left;
    margin-top: 2.4rem;
    margin-bottom: 3.2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 4rem;
  }

  &__buttons {
    border-bottom: 1px solid $lightGrey;
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;

    > button {
      padding: 1.5rem 0;
      > span {
        color: $primary-main;
      }
    }

    > button {
      &:first-child {
        > span {
          color: $white;
        }
      }

      &:last-child {
        background: $white;
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;

    > span:first-child {
      color: $text-secondary;
      font-size: 1.2rem;
    }

    > span:last-child {
      margin-top: -2px;
    }

    &--country {
      display: flex;
      align-items: center;
    }

    &--copy {
      display: flex;

      > span {
        margin-left: 0.4rem;
      }
    }
  }

  &__message--warning {
    font-size: 1.2rem;
    color: $black !important;
    font-weight: 500;
  }

  &__message--credit {
    margin-bottom: 1.6rem;
    p {
      font-size: 1.6rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mobile {
    margin-top: 5.7rem;
    padding: 2rem 1.2rem;
    > h2 {
      position: absolute;
      right: 0;
      top: 2.5rem;
      left: 0;
    }
    &__content {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-auto-flow: row;
    }
  }
}
